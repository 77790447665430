import React, { useState, useEffect } from "react";
import Spinner from "../../utils/spinner/Spinner";
import { toast } from "react-toastify";
import { useSymbolContext } from "../../../contexts/Symbol-Context.js";
import { API_ENDPOINT_OPEN_ORDER } from "../../../data/Endpoints-API.js";
import APIMiddleware from "../../../data/api/Api-Middleware.js";
import { useAccountManagerContext } from "../../../contexts/Account-Manager-Context.js";
import { useAuthContext } from "../../../contexts/Auth-Context.js";
import { useMetricsContext } from "../../../contexts/Metrics-Context.js";
import { Order } from "../../../interfaces/Order.js";
import { formatPositionToPipSize, formatPrice } from "../../../utils/format.js";
import { useOrderContext } from "../../../contexts/Order-Context.js";
import SLTP from "../market_order/SLTP.jsx";
import CreatableSelect from 'react-select/creatable';

import { Ripple } from 'react-ripple-click';
import 'react-ripple-click/dist/index.css';
import UTCTimeConverter from "../order_panel/UTCTimeConverter.jsx";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

//Declaring props
interface ChildProps {
  selectedOrderTab: any;
}
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '15px !important',
    width: '150px',
    borderColor: '#484848 !important',
    backgroundColor: 'transparent !important',
    boxShadow: state.isFocused ? '#484848 !important' : provided.boxShadow,
    '&:hover': {
      borderColor: state.isFocused ? '#484848 !important' : provided.borderColor,
      cursor: 'pointer',
    },
    cursor: 'pointer',
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '10px !important',
    color: 'white',
    // marginLeft: '10px !important',
    borderColor: '#484848 !important',
    backgroundColor: state.isFocused ? 'rgb(33, 196, 109) !important;' : null,
    '&:hover': {
      backgroundColor: 'rgb(33, 196, 109) !important',
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#3b3a3a !important',
    zIndex: 111,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '120px',
    overflowY: 'auto',
  }),
  input: (provided) => ({
    ...provided,
    padding: '0 !important'
  }),
};
const orderCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '15px !important',
    width: '75px',
    borderColor: '#484848 !important',
    backgroundColor: 'transparent !important',
    boxShadow: state.isFocused ? '#484848 !important' : provided.boxShadow,
    '&:hover': {
      borderColor: state.isFocused ? '#484848 !important' : provided.borderColor,
      cursor: 'pointer',
    },
    cursor: 'pointer',
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '10px !important',
    color: 'white',
    // marginLeft: '10px !important',
    borderColor: '#484848 !important',
    backgroundColor: state.isFocused ? 'rgb(33, 196, 109) !important;' : null,
    '&:hover': {
      backgroundColor: 'rgb(33, 196, 109) !important',
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#3b3a3a !important',
    zIndex: 111,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '120px',
    overflowY: 'auto',
  }),
  input: (provided) => ({
    ...provided,
    padding: '0 !important'
  }),
};

const PendingOrder: React.FC<ChildProps> = ({ selectedOrderTab }) => {
  //CONTEXT
  const {
    loadingSymbolContext,
    
    bidPrice,
    askPrice,
    symbolInfo,
    marketHours,
    symbolData,
    updateSymbolData,
    allSymbolOptions,
    selectedSymbolOption,
    handleSymbolOptionChange,
    leverage,
    selectedSymbolExchangeRate,
    lotSteps,
    lotSize,
    unitOptions
  } = useSymbolContext();
  const { openOrder, setActiveTab } = useAccountManagerContext();
  const { user, sendDataToServer,selectedAuthSymbol,platFromData } = useAuthContext();
  const { metrics } = useMetricsContext();
  const [buyActive, setBuyActive] = useState(true);
  const [sellActive, setSellActive] = useState(false);
  const [direction, setDirection] = useState("Buy");
  const [orderQuantity, setOrderQuantity] = useState(0.0);
  const [stopLoss, setStopLoss] = useState(0.0);
  const [slPips, setSlPips] = useState(0.0);
  const [tpPips, setTpPips] = useState(0.0);
  const [takeProfit, setTakeProfit] = useState(0.0);
  const [orderEntryPrice, setOrderEntryPrice] = useState(0.0);
  const [expiresAt, setExpiresAt] = useState("");

  const [pointerEvents, setPointerEvents] = useState("all");

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [preDefineQuantities, setPreDefineQuantities] = useState("false");
  const [orderComment, setOrderComment] = useState("");
  const [assetState, setAssetState] = useState({
    type: "base_asset",
    name: symbolInfo.base_asset,
  });
  const [amount, setAmount] = useState(0.0);
  const [expiresAtStatus, setExpiresAtStatus] = useState(false);
  const [isOrderOpening, setIsOrderOpening] = useState(false);
  const [selectedLotStep, setSelectedLotStep] = useState(0.0);
  const [selectedLotStepOption, setSelectedLotStepOption] = useState({ value: 0.0, label: '0.0 Lots' });
  const [inputValue, setInputValue] = useState('');
    
  const [inputQtyValue, setInputQtyValue] = useState('');
  // const [selectedQty, setSelectedQty] = useState(0.0);
  const [selectedQtyOption, setSelectedQtyOption] = useState({ value: 0.0, label: '0.0' });
  const formatCreateLabelLots = (inputValue) => `${inputValue} Lots`;
  const formatCreateLabelQty = (inputValue) => `${inputValue}`;



  const handleProfitChange = (data) => {
    setTakeProfit(data)
  };
  const handleLossChange = (data) => {
    setStopLoss(data)
  };
  const handleTPPipsChange = (data) => {
    setTpPips(data)
  };
  const handleSLPipsChange = (data) => {
    setSlPips(data)
  };

  useEffect(() => {
    setAssetState({type: "base_asset",
    name: symbolInfo.base_asset});
    if (symbolInfo.trade_type === 'units') {
      const unitFirst = unitOptions?.length > 0 ? unitOptions[0] : { value: 0.0, label: '0.0' };
      // setSelectedQty(parseFloat(unitFirst.value));
      setOrderQuantity(parseFloat(unitFirst.value));
      setSelectedQtyOption(unitFirst);
    } else {
      const lotFirst = lotSteps?.length > 0 ? lotSteps[0] : { value: 0.0, label: '0.0' };
      setSelectedLotStep(parseFloat(lotFirst.value));
      setSelectedLotStepOption(lotFirst);
    }
  }, [lotSteps, symbolInfo, unitOptions]);

  useEffect(() => {
    // setEntryPrice(direction == "Buy" ? bidPrice : askPrice);
  }, [
    bidPrice,
    askPrice,
    orderEntryPrice,
  ]);

  useEffect(() => {
    setOrderQuantity(selectedLotStep * lotSize)
 }, [selectedLotStep]);

  useEffect(() => {
    if (selectedOrderTab === "pending-order" && bidPrice > 0 && askPrice) {
      setOrderEntryPrice(
        direction == "Buy" ? parseFloat(askPrice) : parseFloat(bidPrice)
      );
    }
  }, [selectedOrderTab, direction, selectedAuthSymbol]);

  useEffect(() => {
    setTakeProfit(0.0);
    setStopLoss(0.0);
    setSelectedLotStep(0.0);
  }, [selectedAuthSymbol]);


  const handleDateTimeChange = (event) => {
    setExpiresAt(event);
  };
  const handleDisabledButton = (data) => {
    setIsButtonDisabled(data)
  };
  useEffect(() => {
    //If amount entry price and quantity is not null or nan then it will pass to the condition
    if (!isNaN(amount) && !isNaN(orderEntryPrice) && !isNaN(orderQuantity)) {
      //If the selected type is base asset (BTC)
      //then it will set amount according to given quantity
      if (assetState.type === "base_asset") {
        setAmount(orderEntryPrice * orderQuantity);
      }
      //If the selected type is quote asset (USDT)
      //then it will set quantity according to given amount
      else if (assetState.type === "quote_asset") {
        setOrderQuantity(amount / orderEntryPrice);
      }
    }
  }, [amount, orderQuantity, orderEntryPrice]);

  const handleAssetChange = (e) => {
    const { value } = e.target;

    setAmount(0.0);
    setOrderQuantity(0.0);
    setInputQtyValue('');
    setSelectedQtyOption({ value: 0.0, label: '0.0' });
    // Create a new object with updated values based on selection
    const updatedAssetState = {
      type: value === symbolInfo.base_asset ? "base_asset" : "quote_asset",
      name: value,
    };

    // Update the state using the updated object
    setAssetState(updatedAssetState);
  };

  const expiryDate = (e) => {
    if (e.target.checked == true) {
      e.target.parentNode.children[0].setAttribute("aria-checked", true);
      // e.target.parentNode.children[2].removeAttribute("disabled");
      setExpiresAtStatus(e.target.checked);
    } else {
      e.target.parentNode.children[0].setAttribute("aria-checked", false);
      // e.target.parentNode.children[2].setAttribute("disabled", true);
      setExpiresAt("");
      setExpiresAtStatus(e.target.checked);
    }
  };
  const getMinimumLeverage = (userLeverage, groupLeverage, symbolLeverage) => {

    const parsedUserLeverage = parseFloat(userLeverage);
    const parsedGroupLeverage = parseFloat(groupLeverage);
    const parsedSymbolLeverage = parseFloat(symbolLeverage);
    // If groupLeverage is defined and not NaN
    if (groupLeverage !== undefined && groupLeverage !== null && groupLeverage !== 'null' && !isNaN(parsedGroupLeverage)) {
      return Math.min(parsedUserLeverage, parsedGroupLeverage);
    }
  
    // If symbolLeverage is defined and not NaN
    if (symbolLeverage !== undefined && symbolLeverage !== null && symbolLeverage !== 'null' && !isNaN(parsedSymbolLeverage)) {
      return Math.min(parsedUserLeverage, parsedSymbolLeverage);
    }
  
    // Default to userLeverage or 1 if userLeverage is NaN
    return isNaN(parsedUserLeverage) ? 1 : parsedUserLeverage;
  };

  const countLetters = (e) => {
    const counter = e.target.value.split("").length;
    const countBox = document.getElementById("count-comment-letters-po");
    if (counter <= 100 && countBox != null) {
      countBox.innerText = counter;
    } else {
      e.target.value = e.target.value.slice(0, 100);
    }
  };

  const handleBuySellOrderClick = (buy: boolean) => {
    setBuyActive(buy);
    setSellActive(!buy);
    setDirection(buy ? "Buy" : "Sell");
    // setEntryPrice(buy ? bidPrice : askPrice);
  };

  const placeOrder = () => {
    if (user && user.userId != undefined && user.userId > 0) {
      setIsOrderOpening(true);
      setActiveTab("open-orders-acc");
      placePendingOrder();
    }
  };

  const placePendingOrder = () => {
    if (orderEntryPrice > 0) {      
      if ((buyActive || sellActive) && orderQuantity > 0 && orderEntryPrice > 0) {
        // Calculate required margin based on trading logic
        const requiredMargin = calculateOrderRequiredMargin();
  
        // Check if the user has enough balance
        if (metrics.equity >= requiredMargin) {
          // Continue with opening the position
          openPendingOrder_api(requiredMargin);
        } else {
          // Show an alert for insufficient balance
          toast.error("Insufficient equity to open the order.", {
            position: "top-right",
          });
          document.getElementById("closeSound").play();
          setIsOrderOpening(false);
        }
      } else {
        toast.error("Order Quantity should be greater than 0.", {
          position: "top-right",
        });
        setIsOrderOpening(false);
      }
    }else{
      toast.error("Entry Price should be greater than 0.", {
        position: "top-right",
      });
      setIsOrderOpening(false);
    }
  };

  const calculateOrderRequiredMargin = () => {
    let reqMarginWithoutLeverage = orderQuantity * orderEntryPrice * selectedSymbolExchangeRate;

    // console.log(platFromData[6],"platFromData[6]platFromData[6]platFromData[6]")
    if (
      platFromData[6] &&
      platFromData[6].availableLeverage &&
      Array.isArray(platFromData[6].availableLeverage) &&
      platFromData[6].availableLeverage.length > 0 &&
      platFromData[6].availableLeverage[0]?.available_leverage &&
      Array.isArray(platFromData[6].availableLeverage[0].available_leverage) &&
      platFromData[6].availableLeverage[0].available_leverage.length > 0 &&
      Array.isArray(platFromData[6].availableLeverage[0].available_leverage[0]?.group_level_leverage) &&
      platFromData[6].availableLeverage[0].available_leverage[0].group_level_leverage.length > 0
    ){
    
          let symbolLeverage = platFromData[6]?.availableLeverage[0]?.available_leverage[0].group_level_leverage.sort((a, b) => {
            // Handle missing or invalid `exposure_level` values using parseFloat
            const aLevel = a && a.exposure_level !== null && a.exposure_level !== undefined
              ? parseFloat(a.exposure_level)
              : Infinity;
            const bLevel = b && b.exposure_level !== null && b.exposure_level !== undefined
              ? parseFloat(b.exposure_level)
              : Infinity;
            return aLevel - bLevel;
          });
    
     let groupMinLeverage =symbolLeverage.filter(lev => {
        // Ensure `lev.exposure_level` exists and is a valid number before comparison
        const exposureLevel = lev && lev.exposure_level !== null && lev.exposure_level !== undefined
          ? parseFloat(lev.exposure_level)
          : NaN;
        return !isNaN(exposureLevel) && exposureLevel >= reqMarginWithoutLeverage;
      })
    
    
    
    
    let groupDefaultLeverage
    
    if(platFromData[6]?.availableLeverage[0]?.available_leverage[0].group_level_leverage.length > 0 && groupMinLeverage.length ==0){
      // console.log('llllll,',symbolLeverage)
    groupDefaultLeverage = symbolLeverage[symbolLeverage.length-1]
    }else{
    groupDefaultLeverage = groupMinLeverage[0];
    }
          
    // console.log(groupMinLeverage.length,groupDefaultLeverage,platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage, "after groupDefaultLeverage",reqMarginWithoutLeverage);
      
        let minimumLeverage = 1;
        if (platFromData[6]?.availableLeverage[0]?.available_leverage[0]) {
                minimumLeverage = getMinimumLeverage(
                  platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage,
                groupDefaultLeverage.max_leverage,
                []
            );
        }
        const lvg = minimumLeverage || 1;
        // console.log(minimumLeverage,"minimumLeverage",lvg)
        // const requiredMargin = quantity * (getEntryPrice() / lvg);
        const converted_entry_price =  orderEntryPrice * selectedSymbolExchangeRate
        const requiredMargin = orderQuantity  * (converted_entry_price / lvg);
        // return isNaN(requiredMargin) ? 0 : requiredMargin.toFixed(4);
        return isNaN(requiredMargin)
        ? 0
        :requiredMargin<1?requiredMargin.toFixed(5)
        : Math.round(requiredMargin * 10) / 10
        // : requiredMargin.toFixed(5)
      } else if (
        platFromData[6] &&
        platFromData[6].availableLeverage &&
        Array.isArray(platFromData[6].availableLeverage) &&
        platFromData[6].availableLeverage.length > 0 &&
        platFromData[6].availableLeverage[0]?.available_leverage &&
        Array.isArray(platFromData[6].availableLeverage[0].available_leverage) &&
        platFromData[6].availableLeverage[0].available_leverage.length > 0 &&
        Array.isArray(platFromData[6].availableLeverage[0].available_leverage[0]?.symbol_default_leverage) &&
        platFromData[6].availableLeverage[0].available_leverage[0].symbol_default_leverage.length > 0
      ){
        let symbolLeverage = platFromData[6]?.availableLeverage[0]?.available_leverage[0].symbol_default_leverage.sort((a, b) => {
          // Handle missing or invalid `exposure_level` values using parseFloat
          const aLevel = a && a.exposure_level !== null && a.exposure_level !== undefined
            ? parseFloat(a.exposure_level)
            : Infinity;
          const bLevel = b && b.exposure_level !== null && b.exposure_level !== undefined
            ? parseFloat(b.exposure_level)
            : Infinity;
          return aLevel - bLevel;
        })
        
        let symbolMinLeverage= symbolLeverage.filter(lev => {
          // Ensure `lev.exposure_level` exists and is a valid number before comparison
          const exposureLevel = lev && lev.exposure_level !== null && lev.exposure_level !== undefined
            ? parseFloat(lev.exposure_level)
            : NaN;
          return !isNaN(exposureLevel) && exposureLevel >= reqMarginWithoutLeverage;
        })
      
      
      // console.log(symbolMinLeverage,platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage, "before symbol_default_leverage",reqMarginWithoutLeverage);
    
      let symbolDefaultLeverage
    
      if(platFromData[6]?.availableLeverage[0]?.available_leverage[0].symbol_default_leverage.length > 0 && symbolMinLeverage.length ==0){
        symbolDefaultLeverage = symbolLeverage[symbolLeverage.length-1]
        }else{
        symbolDefaultLeverage = symbolMinLeverage[0];
        }
              
    // console.log(symbolDefaultLeverage,platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage, "after symbol_default_leverage",reqMarginWithoutLeverage);
      
    let minimumLeverage = 1;
    if (platFromData[6]?.availableLeverage[0]?.available_leverage[0]) {
            minimumLeverage = getMinimumLeverage(
            platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage,
            [],
            symbolDefaultLeverage.max_leverage,
            
        );
    }
    const lvg = minimumLeverage || 1;
    // console.log(minimumLeverage,"minimumLeverage",lvg)
    // const requiredMargin = quantity * (getEntryPrice() / lvg);
    const converted_entry_price =  orderEntryPrice * selectedSymbolExchangeRate
    const requiredMargin = orderQuantity  * (converted_entry_price / lvg);
    // return isNaN(requiredMargin) ? 0 : requiredMargin.toFixed(4);
    return isNaN(requiredMargin)
    ? 0
    :requiredMargin<1?requiredMargin.toFixed(5)
    : Math.round(requiredMargin * 10) / 10
      }else if (platFromData[6] &&
        platFromData[6].availableLeverage &&
        Array.isArray(platFromData[6].availableLeverage) &&
        platFromData[6].availableLeverage.length > 0 &&
        platFromData[6].availableLeverage[0]?.available_leverage &&
        Array.isArray(platFromData[6].availableLeverage[0].available_leverage) &&
        platFromData[6].availableLeverage[0].available_leverage.length > 0 &&
        platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage){

        const lvg = platFromData[6]?.availableLeverage[0]?.available_leverage[0].user_default_leverage || 1;
        console.log("minimumLeverage",lvg)
    // const requiredMargin = quantity * (getEntryPrice() / lvg);
    const converted_entry_price =  orderEntryPrice * selectedSymbolExchangeRate
    const requiredMargin = orderQuantity  * (converted_entry_price / lvg);
    // return isNaN(requiredMargin) ? 0 : requiredMargin.toFixed(4
        return isNaN(requiredMargin)
        ? 0
        :requiredMargin<1?requiredMargin.toFixed(5)
        : Math.round(requiredMargin * 10) / 10
    
      }
  };

  //#region API CALLS

  const openPendingOrder_api = async (margin) => {
    try {
      const currentDate = new Date();
      const currentDateTime = new Date(currentDate);
      // const exp = new Date(expiresAt);
      // const expdt = new Date(exp);

      const data: Order = {
        id: -1,
        created_at: null,
        symbol: selectedAuthSymbol,
        quantity: orderQuantity,
        amount: amount,
        asset_type: assetState.type,
        direction: direction,
        entry_price: orderEntryPrice,
        converted_entry_price: orderEntryPrice * selectedSymbolExchangeRate,
        TP: takeProfit,
        SL: stopLoss,
        netEUR: 0, // Set appropriate values
        status: "",
        quote_asset:symbolInfo.quote_asset,
        userId: user.userId,
        margin: margin,
        exit_price: 0,
        totalUnrealizedPnL: metrics.totalUnrealizedPnL,
        order_id: "OID" + Math.floor(100000 + Math.random() * 900000),
        status_updated_at: null,
        expires_at: expiresAt ? expiresAt : null,
        comment: orderComment,
        exchange_rate: selectedSymbolExchangeRate,
        lot_step: selectedLotStep,
        trade_type: symbolInfo.trade_type,
        lot_size: lotSize
      };

      const response = await APIMiddleware.post(API_ENDPOINT_OPEN_ORDER, data);

      const data_m = {
        ...response.data,
        created_at: new Date(response.data.created_at).toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }),
      };

      // Check if the response data matches the Position interface
      //if (Object.keys(response.data).every((key) => key in data)) {
      // openOrder(data_m);
      sendDataToServer(4);
      setIsOrderOpening(false);
      // openPosition(data_m);
      //}

      // Show a success notification
      toast.success("Order opened successfully!", { position: "top-right" });
      document.getElementById("openSound").play();
      localStorage.accountManager = "open-orders-acc";
    } catch (error) {
      toast.error(error.response.data.error, { position: "top-right" });
      // Handle API request error
      console.error(`API request error: ${API_ENDPOINT_OPEN_ORDER}`, error);
      setIsOrderOpening(false);
    }
  };

  const handleLotStepOptionChange = (lotStep)=>{
    setSelectedLotStep(parseFloat(lotStep.value));
    setSelectedLotStepOption(lotStep);
  }

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleQtyOptionChange = (option)=>{
    // setSelectedQty(parseFloat(option.value));
    setOrderQuantity(parseFloat(option.value));
    setSelectedQtyOption(option);
  }

  const handleQtyInputChange = (value) => {
    setInputQtyValue(value);
  };

  // const onFocusQty: AriaOnFocus<any> = ({ focused, isDisabled }) => {
  //   if (isDisabled) return '';
  //   const val = parseFloat(focused.value);
  //   // setSelectedQty(val);
  //   setOrderQuantity(val);
  //   setSelectedQtyOption(focused);
  //   return `${focused.label}`;
  // };

  // const onFocusLots: AriaOnFocus<any> = ({ focused, isDisabled }) => {
  //   if (isDisabled) return '';
  //   const val = parseFloat(focused.value);
  //   setSelectedLotStep(val);
  //   setSelectedLotStepOption(focused);
  //   return `${focused.label}`;
  // };

  //#endregion

  //show spinner while loading data
  if (loadingSymbolContext) {
    return <Spinner />;
  }

  return (
    <>
      <div className="price-box-container">
        <div
          className={`price-box price-box-buy ${
            buyActive ? "price-box-buy-active" : ""
          }`}
          id="buyMarketOrder"
          onClick={() => handleBuySellOrderClick(true)}
        >
          <div style={{fontSize: '14px', fontWeight: 'bolder'}}>Buy</div>
        </div>
        <div
          className={`price-box price-box-sell ${
            sellActive ? "price-box-sell-active" : ""
          }`}
          id="sellMarketOrder"
          onClick={() => handleBuySellOrderClick(false)}
        >
          <div style={{fontSize: '14px', fontWeight: 'bolder'}}>Sell</div>
        </div>
      </div>
      <div className="css-flex">
        <div className="box">
          <label htmlFor="stop-loss-market" className="order-label">
            Entry Price:
          </label>
          <input
            type="number"
            id="order-entry-price"
            className="order-input"
            value={orderEntryPrice}
            onChange={(e) => setOrderEntryPrice(parseFloat(e.target.value))}
          />
        </div>
        
{symbolInfo.trade_type === "units" ? (
  <>
    {assetState.type === "base_asset" ? (
      <div className="box">
        <label htmlFor="take-profit-market" className="order-label">
          Quantity:
        </label>
        <div className="lots-selector-container-order">
          <CreatableSelect
                // aria-labelledby="aria-label"
                // ariaLiveMessages={{
                //   onFocus: (p) => onFocusQty(p),
                // }}
                styles={orderCustomStyles}
                onInputChange={handleQtyInputChange}
                inputValue={inputQtyValue}
                value={selectedQtyOption}
                onChange={handleQtyOptionChange}
                options={unitOptions}
                isSearchable
                placeholder="Select Lots"
                formatCreateLabel={formatCreateLabelQty}
                defaultInputValue={inputQtyValue}
                defaultValue={selectedQtyOption}
            />
        </div>
      </div>
    ) : (
      <div className="box">
        <label htmlFor="take-profit-market" className="order-label">
          Amount:
        </label>
        <input
          type="number"
          className="order-input"
          id="amount-input"
          value={amount}
          onChange={(e) => {
            setAmount(parseFloat(e.target.value));
          }}
        />
      </div>
    )}
    <div className="box">
      <label className="order-label">Type:</label>
      <select
        className="asset-select"
        name=""
        id=""
        value={assetState.name}
        onChange={handleAssetChange}
      >
        <option value={symbolInfo.base_asset}>
          {symbolInfo.base_asset}
        </option>
        <option value={symbolInfo.quote_asset}>
          {symbolInfo.quote_asset}
        </option>
      </select>
    </div>
  </>
) : (
  <div className="box">
      <label className="order-label">Lots:</label>
      <div className="lots-selector-container-order">
          <CreatableSelect
          // aria-labelledby="aria-label"
          // ariaLiveMessages={{
          //   onFocus: (p) => onFocusLots(p),
          // }}
          styles={customStyles}
          onInputChange={handleInputChange}
          inputValue={inputValue}
          value={selectedLotStepOption}
          onChange={handleLotStepOptionChange}
          options={lotSteps}
          isSearchable
          placeholder="Select Lots"
          formatCreateLabel={formatCreateLabelLots}
          defaultInputValue={inputQtyValue}
          defaultValue={selectedQtyOption}
          />
      </div>
        </div>
)}
      </div>
      <div className="reuqired-margin">
        <div className="title">Required Margin</div>
        <div className="value">
          {calculateOrderRequiredMargin()}{" "}
          {user?.userCurrencyName ||"EUR"}
        </div>
      </div>
      {/* <div className="check-box-expiry"> */}
        {/* <label htmlFor="scss-checkbox" aria-checked="false">
          <div className="custom-box"></div>
          <p>Expiry</p>
        </label> */}
        {/* <input
          type="checkbox"
          id="scss-checkbox"
          hidden
          onChange={(e) => expiryDate(e)}
        /> */}
        {/* <input
          type="datetime-local"
          id="date-time"
          value={expiresAt}
          // onChange={handleDateTimeChange}
          onChange={(e) => handleDateTimeChange(e.target.value)}
          disabled={!expiresAtStatus}
        />

        {/* <DatePicker
          selected={expiresAt}
          onChange={(e) => handleDateTimeChange(e)}
          // dateFormat="yyyy/MM/dd" // customize date format as needed
          // dateFormat="MMMM d, yyyy h:mm aa"
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy h:mm aa"
          showTimeInput
          calendarClassName="expiry-input"
          disabled={!expiresAtStatus}
          placeholderText="yyyy/MM/dd"
        /> */}
      {/* </div> */}
      {selectedOrderTab === "pending-order"?
      <SLTP isPendingOrder={true} isEditPosition={false} handleDisabledButton={handleDisabledButton} handleSLPipsChange={handleSLPipsChange} handleTPPipsChange={handleTPPipsChange} handleProfitChange={handleProfitChange} handleLossChange={handleLossChange} direction={direction} quantity={orderQuantity} entryPrice={orderEntryPrice} />
     : <></>}
      <div className="text-bx">
        <div className="text-area-letter-counter-sltp">
          <span id="count-comment-letters-po">0</span>/100
        </div>
        <textarea
          className="comment-sltp-lst"
          placeholder="Comment"
          onInput={(e) => {
            countLetters(e);
          }}
          value={orderComment}
          onChange={(e) => {
            setOrderComment(e.target.value);
          }}
        ></textarea>
      </div>

      <div className="place-order">
        {/* Conditionally set the disabled attribute based on the user's authentication status */}
        <button
           title={
              
            platFromData[5]?.accessRight == 3
            ? "Trading for this Account in Disabled"
            :platFromData[5]?.accessRight == 2
              ? "The status of this account is set to Close Only . You can only close your existing Positions" 
              : ""
        } 
          disabled={platFromData[5]?.accessRight == 2 ||platFromData[5]?.accessRight == 3|| localStorage.getItem('accountType') =="0" ||!bidPrice ||!askPrice ||isOrderOpening}
          className={`place-order-button ${
            buyActive ? "place-order-button-buy" : "place-order-button-sell"
          }`}
          onClick={() => {
            placeOrder();
            // mkRipple(e);
          }}
          style={{
            position: 'relative',
            overflow: 'hidden',
            isolation: 'isolate',
          }}
        >
          <Ripple
          // color="rgba(255, 255, 255, 0.3)" 
          />
          <b style={{fontSize: '14px', fontWeight: 'bolder'}} >
            {!isOrderOpening ? direction : "Loading..."}
          </b>
          <br />
          <span style={{fontSize: '13px'}} >
            {orderQuantity || ""} {selectedAuthSymbol} @ {orderEntryPrice}
          </span>
        </button>
      </div>
    </>
  );
};

export default PendingOrder;
