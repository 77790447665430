import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import APIMiddleware from "../../../data/api/Api-Middleware.js";
import { API_ENDPOINT_GET_All_CLOSED_ORDERS } from "../../../data/Endpoints-API";
import { useAuthContext } from "../../../contexts/Auth-Context";
import { adjustDateTime, formatDate, formatDigitBasePrice } from "../../../utils/format.js";
import "./Positions.css";
import { useAccountManagerContext } from "../../../contexts/Account-Manager-Context.js";
import { useMetricsContext } from "../../../contexts/Metrics-Context.js";
const OrderHistory = ({name,parentRef, currTab, handleRowClick, isSymbolSelecting, mobileMode}) => {
  const { utcOffset } =  useMetricsContext();
  const { user, platFromData } = useAuthContext();
  const { setOrderHistoryCount,isOrderClosing } = useAccountManagerContext();
  const [radioChecked, setRadioChecked] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectDisabled2, setSelectDisabled2] = useState(false);
  const [selectDisabled, setSelectDisabled] = useState(false);
  const [symbolOrId, setSymbolOrId] = useState("");
  const [radioChecked2, setRadioChecked2] = useState(true);
  const [filter, setFilter] = useState("");
  const [selectedOrderDate, setselectedOrderDate] = useState("all");
  const [isCustomRangeSelected, setIsCustomRangeSelected] = useState(false);
  const [search, setSearch] = useState("");
  const [orderDate, setOrderDate] = useState({
    startDate: "2000-01-01 00:00:00",
    endDate: getTodaysEnd(),
  });
  const [datePickar, setDatePickar] = useState({
    startDate: "2000-01-01 00:00:00",
    endDate: getTodaysEnd(),
  });
  const customStyles = {
    headCells: {
      style: {
        justifyContent: 'flex-start !important',
        paddingLeft:'13px !important'
      },
    },
    cells: {
      style: {
        paddingLeft:'13px !important',
        justifyContent: 'flex-start !important',
        cursor: `${!mobileMode ? isSymbolSelecting ? 'wait' : 'pointer' : ''}`,
      },
    },
    pagination: {
      style: {
        // width: '100vw',
        minHeight: '30px',
        height: '30px'
      },
    },
    headRow: {
      style: {
        minHeight: '10px',
        height: '30px'
      },
      denseStyle: {
        minHeight: '10px',
        height: '30px'
      },
    },
  };
  createTheme(
    "solarized",
    {
      text: {
        primary: "#c5c5c5",
      },
      background: {
        default: "#2d2d2d",
      },
      context: {
        background: "#2d2d2d",
        text: "#c5c5c5",
      },
    },
    "dark"
  );

  function getTodaysEnd() {
    const today = new Date();

    // Set the time to the end of the day (23:59:59)
    today.setHours(23, 59, 59, 999); // Hours, minutes, seconds, milliseconds

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month (0-indexed) with leading zero
    const day = String(today.getDate()).padStart(2, "0");
    const hours = String(today.getHours()).padStart(2, "0");
    const minutes = String(today.getMinutes()).padStart(2, "0");
    const seconds = String(today.getSeconds()).padStart(2, "0");

    // Format the date and time in the desired format
    const formattedTimestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedTimestamp;
  }

  function convertDatetime(datetimeString) {
    // Parse the date string using a date object
    const date = new Date(datetimeString);

    // Get year, month (0-indexed), day, hours, minutes, and seconds
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero for single-digit months
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Format the date in the desired format
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  useEffect(() => {
    const calculateRowsPerPage = () => {
      if (parentRef?.current) {
        const divHeight = parentRef.current.clientHeight;
        const rowHeight = 35;
        let rows;
        if (window.innerHeight < 960) {
          rows = Math.floor((divHeight * 0.6) / rowHeight);
        } else rows = Math.floor((divHeight * 0.8) / rowHeight);
        // console.log('order history rows: ', rows);
        setPageSize(rows > 10 ? 10 : rows);
      } else {
        setPageSize(5);
      }
    };
    // Call the function if currTab = history
    if (currTab === 'history') calculateRowsPerPage();
  }, [currTab, parentRef]);

  useEffect(() => {
    const fetchData = async () => {
      var startDate = convertDatetime(orderDate.startDate);
      var endDate = convertDatetime(orderDate.endDate);
      if (isCustomRangeSelected) {
        startDate = convertDatetime(datePickar.startDate);
        endDate = convertDatetime(datePickar.endDate);
      }
      try {
        const response = await APIMiddleware.get(
          API_ENDPOINT_GET_All_CLOSED_ORDERS(
            user.userId,
            currentPage,
            pageSize,
            search,
            startDate,
            endDate
          )
        );
        setData(response.data);
        setTotal(response.total);
        setOrderHistoryCount(response.total);
      } catch (error) {
        console.error(error);
      }
    };

    if(!isOrderClosing){
      fetchData();
    }
  }, [
    currentPage,
    pageSize,
    filter,
    search,
    orderDate,
    datePickar,
    isCustomRangeSelected,
    platFromData[4]?.closedOrders,
  ]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value); // Update search state
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.order_id,
    },
    {
      name: "Close/Expired At",
      selector: (row) =>
        formatDate(adjustDateTime(row.created_at,utcOffset)),
      style: {
        fontSize: "10px",
      },
    },
    {
      name: "Symbol",
      selector: (row) => row.symbol,
    },
    {
      name: "Quantity",
      // selector: (row) => parseFloat(row.quantity).toFixed(6),
      selector: (row) => (row.lot_step == 0 || !row.lot_step) ? formatDigitBasePrice(row.quantity, 7) + " Units" : row.lot_step + " Lots",
    },
    {
      name: "Direction",
      selector: (row) => row.direction,
    },
    {
      name: "Entry",
      selector: (row) => parseFloat(row.entry_price).toFixed(2),
    },
    {
      name: "Exit",
      selector: (row) => row.exit_price,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
  ];
  const paginationComponentOptions = {
    noRowsPerPage: true,
  };
  const handleFirstDivClick2 = () => {
    setRadioChecked2(true);
    setSelectDisabled2(false); // Ensure select is enabled when clicking on the first div
    setIsCustomRangeSelected(false);
  };
  const handleFirstDivClick = () => {
    setRadioChecked(true);
    setSelectDisabled(false); // Ensure select is enabled when clicking on the first div
  };

  function changehanldefunction(e) {
    const value = e.target.value;
    setselectedOrderDate(value);
    if (value === "Today") {
      const today = new Date(); // Get today's date

      // First date starting from midnight
      const startDate = new Date(today);
      startDate.setHours(0, 0, 0, 0); // Set time to midnight (0 hours, 0 minutes, 0 seconds, 0 milliseconds)

      // Second date ending at 23:59:59.999
      const endDate = new Date(today);
      endDate.setHours(23, 59, 59, 999); // Set time to 23:59:59.999 (23 hours, 59 minutes, 59 seconds, 999 milliseconds)

      const dateobj = {
        startDate: startDate,
        endDate: endDate,
      };
      setOrderDate(dateobj);
      // console.log("Today", dateobj)
    } else if (value === "Yesterday") {
      const today = new Date(); // Get today's date

      // Subtract one day from today to get yesterday's date
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      // First date starting from midnight
      const startDate = new Date(yesterday);
      startDate.setHours(0, 0, 0, 0); // Set time to midnight (0 hours, 0 minutes, 0 seconds, 0 milliseconds)

      // Second date ending at 23:59:59.999
      const endDate = new Date(yesterday);
      endDate.setHours(23, 59, 59, 999); // Set time to 23:59:59.999 (23 hours, 59 minutes, 59 seconds, 999 milliseconds)

      yesterday.setDate(today.getDate() - 1);
      const dateobj = {
        startDate: startDate,
        endDate: endDate,
      };
      setOrderDate(dateobj);
      // console.log("Yesterday", dateobj)
    } 
    else if (value === "CurrentWeek") {
      const today = new Date();
      const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay())); // Get the start of the current week (Sunday)
      const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6)); // Get the end of the current week (Saturday)
    
      // Set start date time to 00:00:00
      startOfWeek.setHours(0, 0, 0, 0);
      // Set end date time to 23:59:59
      endOfWeek.setHours(23, 59, 59, 999);
    
      const dateobj = {
        startDate: startOfWeek,
        endDate: endOfWeek,
      };
      setOrderDate(dateobj);
    }
    
    else if (value === "CurrentMonth") {
      const today = new Date(); // Get today's date
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // First day of the month
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Last day of the month
    
      // Set start date time to 00:00:00
      firstDayOfMonth.setHours(0, 0, 0, 0);
      // Set end date time to 23:59:59
      lastDayOfMonth.setHours(23, 59, 59, 999);
    
      const dateobj = {
        startDate: firstDayOfMonth,
        endDate: lastDayOfMonth,
      };
      setOrderDate(dateobj);
    }
    
    else if (value === "PreviousMonth") {
      const today = new Date(); // Get today's date
      const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1); // First day of the current month
      const firstDayOfPreviousMonth = new Date(firstDayOfCurrentMonth); // Copy the date
      firstDayOfPreviousMonth.setMonth(firstDayOfPreviousMonth.getMonth() - 1); // Set it to the previous month
      const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth); // Copy the date again
      lastDayOfPreviousMonth.setDate(0); // Set it to the last day of the previous month
    
      // Set start date time to 00:00:00
      firstDayOfPreviousMonth.setHours(0, 0, 0, 0);
      // Set end date time to 23:59:59
      lastDayOfPreviousMonth.setHours(23, 59, 59, 999);
    
      const dateobj = {
        startDate: firstDayOfPreviousMonth,
        endDate: lastDayOfPreviousMonth,
      };
      setOrderDate(dateobj);
    }
    
    else if (value === 'LastThreeMonth') {
      const today = new Date(); // Get today's date
      const firstDateThirdMonthAgo = new Date(today.getFullYear(), today.getMonth() - 2, 1); // First day of the third last month
      const lastDateCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Last day of the current month
    
      // If the current day is not the first day of the month,
      // include the remaining days of the current month
      if (today.getDate() !== 1) {
        lastDateCurrentMonth.setDate(today.getDate()); // Set the last day of the current month to today's date
      }
    
      const dateobj = {
        startDate: firstDateThirdMonthAgo,
        endDate: today // Set the end date to today's date
      };
     
      setOrderDate(dateobj);
    }
    else if (value === "Last6Month") {
      const today = new Date();
      const firstDateSixMonthAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 5,
        1
      ); 
      const lastDateCurrentMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      ); 
      if (today.getDate() !== lastDateCurrentMonth.getDate()) {
        lastDateCurrentMonth.setDate(today.getDate()); // Set the last day of the current month to today's date
      }
    
      lastDateCurrentMonth.setHours(23, 59, 59, 999);
    
      const dateobj = {
        startDate: firstDateSixMonthAgo,
        endDate: lastDateCurrentMonth,
      };
    
      setOrderDate(dateobj);
    }    
    else if (value === "all") {
        const dateobj = {
            startDate: "2000-01-01 00:00:00",
            endDate: getTodaysEnd(),
        };
    
        setOrderDate(dateobj);
    } else {
        const dateobj = {
            startDate: "2000-01-01 00:00:00",
            endDate: getTodaysEnd(),
        };
    
        setOrderDate(dateobj);
    }
  }
  const handleSecondDivClick2 = () => {
    setRadioChecked2(false);
    setSelectDisabled2(true); // Disable select when clicking on the second div
    setIsCustomRangeSelected(true);
  };
  const handleDateInputClick = (event) => {
    event.stopPropagation(); // Prevent event propagation to avoid affecting div clicks
  };
  const datePickarFunction = (event, key) => {
    const newDate = new Date(event.target.value);
    if (key === "endDate") {
      newDate.setHours(23, 59, 59, 999);
    } else if (key === "startDate") {
      newDate.setHours(0, 0, 0, 0);
    }
    setDatePickar((prev) => ({ ...prev, [key]: newDate }));
  };
  return (
    <div className="history-section">
      <div className="bottom-table-bstorm">
        <div className="positions-button" style={{flexWrap: "wrap", gap: "4px"}}>
          <div className="input-text" >
            <input
              type="text"
              value={search}
              onChange={handleSearchChange}
              placeholder="Search Symbol.."
            />
            <div className="input-svg" id="searchSvgColor">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </div>
          </div>
          <div
            className={`flex-tag ${radioChecked2 ? "" : "disabled"}`}
            onClick={handleFirstDivClick2}
          >
            <div className="span-tag">
              <span>Periods:</span>
              <input
                type="radio"
                name={name}
                checked={radioChecked2}
                onChange={() => setRadioChecked2(true)}
              />
            </div>
            <select
              className="select-option"
              style={{ width: "130px" }}
              disabled={selectDisabled2}
              onChange={(e) => changehanldefunction(e)}
            >
              <option value="Today"> Today </option>
              <option value="Yesterday">Yesterday</option>
              <option value="CurrentWeek">Current Week</option>
              <option value="CurrentMonth">Current Month</option>
              <option value="PreviousMonth">Previous Month</option>
              <option value="LastThreeMonth">Last Three Month</option>
              <option value="Last6Month">Last 6 Month</option>
              <option value="all" selected>
                All History
              </option>
            </select>
          </div>

          <div
            className={`flex-tag ${!radioChecked2 ? "" : "disabled"} pt-10`}
            onClick={handleSecondDivClick2}
          >
            <div className="span-tag">
              <span>Periods:</span>
              <input
                type="radio"
                name={name}
                checked={!radioChecked2}
                onChange={() => setRadioChecked2(false)}
              />
            </div>
            <div className="date-time">
              <input
                type="date"
                name=""
                id=""
                disabled={radioChecked2}
                onClick={handleDateInputClick}
                onChange={(e) => datePickarFunction(e, "startDate")}
              />
              <span>---</span>
              <input
                type="date"
                name=""
                id=""
                disabled={radioChecked2}
                onClick={handleDateInputClick}
                onChange={(e) => datePickarFunction(e, "endDate")}
              />
            </div>
          </div>
        </div>

        <div className="overflow-y-table-body">
          <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={total}
            onChangePage={handlePageChange}
            paginationDefaultPage={currentPage}
            // fixedHeader={true}
            // fixedHeaderScrollHeight={'300px'}
            filteringFunction={handleFilterChange}
            filtering={true}
            persistTableHead
            theme="solarized"
            customStyles={customStyles}
            paginationComponentOptions={paginationComponentOptions}
            onRowDoubleClicked={handleRowClick}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
