import React, { useState } from 'react';
import styles from './Switch.module.scss';

function Switch({subscribed, isChecked, handleToggle}) {

    return (
        <div className={`${styles.switch} ${!subscribed ? styles.unsubSwitch : ''}`}>
            <span>
                <input
                    type="checkbox"
                    id="toggleInput"
                    checked={isChecked}
                />
                <button
                    className={styles.slider}
                    type="button"
                    onClick={() => handleToggle(!isChecked)}
                >
                </button>
            </span>
        </div>
    );
}

export default Switch;
